import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Schedule from "@material-ui/icons/Schedule";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import pillsStyle from "assets/jss/nextjs-material-kit/pages/componentsSections/pillsStyle.js";

const UseStyles = makeStyles(pillsStyle);

export default function SectionPills() {
  const classes = UseStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          {/* <div className={classes.title}>
            <h3>Navigation Pills</h3>
          </div>
          <div className={classes.title}>
            <h3>
              <small>With Icons</small>
            </h3>
          </div> */}
          <GridContainer>
            <GridItem xs={12} >
              <NavPills
                color="rose"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 3, md: 2 },
                  contentGrid: { xs: 12, sm: 9, md: 10 }
                }}
                style={{
                  display: 'flex',
                  flexFlow: 'row nowrap',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                tabs={[
                  {
                    tabButton: "Schedule",
                    tabIcon: Schedule,
                    tabContent: (
                      <img src={'/dzien-jednosci-2019.jpg'} alt='dzień jedności 2019' />
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
